<template>
    
    <div id="log-overview" class="w-100">
        <Overview 
            :moduleName="$tc(navName, 1)"
            :moduleNamePlural="$tc(navName, 2)"
            sort="created_at"
            :sortOrder="-1"
            newButtonName=""
            :selectFields=selectFields
            :itemService=itemService
            :itemFormRef=itemFormRef
            :showDropdown="false"
        >
            <template #columns>
                <Column field="controller" header="Module" :sortable="true" style="width: 200px"></Column>
                <Column field="user.email" :header="$t('navigation.users')" :sortable="true" style="width: 300px"></Column>
                <Column field="employee_name" :header="$t('navigation.employees')" :sortable="true" style="width: 200px"></Column>
                <Column field="company_name" :header="$t('navigation.companies')" :sortable="true" style="width: 200px"></Column>
                <Column field="formatted_log" :header="$t('overview.actions')" :sortable="true"></Column>
                <Column field="created_at" :header="$t('overview.date')" :sortable="true" style="width: 200px">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.created_at, 'dateTime')}}
                </template>
                </Column>
            </template>
            <template #form>
                <LogDetail ref="ItemForm" :itemData="item" />
            </template>
        </Overview>
        <a id="log-opener" class="d-none" data-bs-toggle="modal" data-bs-target="#formModal"> </a>
    </div>
</template>

<script>
import LogDetail from '@/components/log/LogDetail.vue';
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import logService from '@/services/LogService'
import store from '@/store/user'

export default {
    components: {
        Overview,
        Column,
        LogDetail
    },
    data() {
        return {
            item: {},
            itemService: logService,
            itemFormRef: null,
            navName: 'navigation.logs',
            companyTypes: [],
            selectFields: []
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>